.org-custom-select {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px;
  background: #fff;

  .option-wrapper {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 6px;
    transition: background 0.2s ease-in-out;
    cursor: pointer;
  }
  
  .option-wrapper:hover {
    background: #e4e4e4;
  }
  
  .option-checkbox {
    margin-right: 10px;
  }
  
  .option-text {
    font-size: 15px;
    color: #000;
  }
}
